import { Dispatch, SetStateAction } from 'react'

import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

import SyncEventBusManager from './SyncEventBusManager'

/**
 * DBW 10/9/24: Rather than use window.addEventListener('online', ...) and
 * window.addEventListener('offline', ...), we use the heartbeatManager to
 * observe the online/offline state. This is because the heartbeatManager is
 * more robust and can handle cases where the client has virtualization software
 * or network adapters that prevent the browser from detecting when it has gone
 * offline (the most common case is a VPN, which especially causes trouble for
 * us internally when testing in support and staging, which are only accessible
 * via VPN, and which is common for our end users as well). There seem to be
 * differences in browser implementations for navigator.onLine, and while Safari
 * is the worst offender, in order to make the implementation easier to
 * understand for developers and more predictable for end users, we reduce
 * complexity by always using the heartbeat manager, and never using the navigator
 * to determine whether the application is offline or not.
 */
class HeartbeatManager {
  private _observers: Dispatch<SetStateAction<boolean>>[] = []
  private _isOnline = navigator.onLine
  private _syncEventBusManager: SyncEventBusManagerInterface

  constructor(isOnline: boolean) {
    this._isOnline = isOnline
    this._syncEventBusManager = new SyncEventBusManager()

    setInterval(() => {
      this.ping()
    }, 5000)
  }

  observe(update: Dispatch<SetStateAction<boolean>>) {
    this._observers.push(update)
    update(this._isOnline)
  }

  get isOnline(): boolean {
    return this._isOnline
  }

  removeObserver(update: Dispatch<SetStateAction<boolean>>) {
    this._observers = this._observers.filter((observer) => observer !== update)
  }

  private setIsOnline(isOnline: boolean) {
    this._isOnline = isOnline
    this._observers.forEach((update) => update(isOnline))
  }

  private ping() {
    // DBW 8/1/2024: We get false positives, but not false negatives, from navigator.onLine,
    // per MDN, so we can safely bomb out when navigator.onLine is false.
    if (!navigator.onLine) {
      this.setIsOnline(false)
      return
    }

    fetch('/ping/')
      .then(() => {
        if (!this._isOnline) {
          this._syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.RECONNECT),
          )
        }
        this.setIsOnline(true)
      })
      .catch(() => {
        if (this._isOnline) {
          this._syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.DISCONNECT),
          )
        }
        this.setIsOnline(false)
      })
  }
}

let heartbeatManager
if (window.offlineHeartbeatManager) {
  heartbeatManager = window.offlineHeartbeatManager
} else {
  heartbeatManager = new HeartbeatManager(navigator.onLine)
  window.offlineHeartbeatManager = heartbeatManager
}

export default heartbeatManager as HeartbeatManager
