import { OfflineApplication } from '@/offline/OfflineApplication'
import { config } from '@/offline/config'
import { DefaultLocalDataManager } from '@/offline/managers/DefaultLocalDataManager'
import { DefaultOfflineManager } from '@/offline/managers/DefaultOfflineManager'

import BackgroundSyncManager from './managers/BackgroundSyncManager'
import SymmetricKeyManager from './managers/SymmetricKeyManager'

// Promise to handle the asynchronous initialization
let resolveInit: (app: OfflineApplication) => void
export const appInitialized: Promise<OfflineApplication> = new Promise(
  (resolve) => {
    resolveInit = resolve
  },
)

// Function to initialize the OfflineApplication instance
const initializeAppInstance = async (): Promise<OfflineApplication> => {
  const localDataManager = new DefaultLocalDataManager(
    config.DB_NAME,
    config.DB_VERSION,
  )
  await localDataManager.initDB()

  const symmetricKeyManager = new SymmetricKeyManager(localDataManager)
  const offlineManager = new DefaultOfflineManager(
    symmetricKeyManager,
    localDataManager,
  )
  const backgroundSyncManager = new BackgroundSyncManager(
    localDataManager,
    symmetricKeyManager,
    config.SYNC_INTERVAL,
  )
  try {
    await backgroundSyncManager.startBackgroundSync()
  } catch (error) {
    // DBW 5/15/2024: We may be offline or logged out, in which case the background sync will fail.
    // We can ignore this error and continue for now, but in the future we should schedule
    // background syncing to restart once we rejoin the network or login.
    console.error('Error starting background sync:', error)
  }

  const appInstance = new OfflineApplication(
    offlineManager,
    backgroundSyncManager,
  )
  appInstance
    .init()
    .then(() => {
      resolveInit(appInstance as OfflineApplication) // Resolve the appInitialized promise
    })
    .catch((error) => {
      console.error('An error occurred during app initialization:', error)
    })
  return appInstance
}

// get singleton
export const getOfflineApp = async (): Promise<OfflineApplication | null> => {
  if (!window.offlineAppInstance) {
    window.offlineAppInstance = initializeAppInstance()
    return window.offlineAppInstance
  } else if (window.offlineAppInstance instanceof Promise) {
    return await window.offlineAppInstance
  } else {
    return window.offlineAppInstance
  }
}

export const initOfflineApp = (): void => {
  const shouldStartOffline = (window as any).offline_mode === 'True'

  // Feature flag for offline mode
  if (shouldStartOffline) {
    // initialize app if not already initialized
    if (document.readyState === 'complete') {
      getOfflineApp()
    } else {
      window.addEventListener('load', () => {
        getOfflineApp()
      })
    }
  }
}

export const iseExistsInCache = async () => {
  // check if sessions tables aren't empty
  const joinLink = document
    .querySelector('a[data-testid="sessionIdentityButton"]')
    ?.getAttribute('href')

  if (!joinLink) {
    // empty sessions list so we have everything cached
    return true
  }

  const cache = await caches.open('twyll-offline')
  const keys = await cache?.keys()
  const urlExists = keys?.some((request) => request.url.includes(joinLink))

  return urlExists
}
